/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 09.10.2015, 11:06:26
    Author     : csh
*/
input[type="button"], button, .hh-btn--normal-color{
    color: white !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#25458a',endColorstr='#012165',GradientType=0);/*IE 7-8-9, requires hex-color for proper colorizing*/
    background-image: linear-gradient(to bottom, rgb(37, 69, 138), rgb(1, 33, 101));/*Standard, IE10*/
    background-image: -webkit-linear-gradient(top, rgb(37, 69, 138), rgb(1, 33, 101));/*Safari 5.1+, Chrome 10+*/
    background-image: -webkit-gradient(linear, 0 0, 100% 0, rgb(37, 69, 138), rgb(1, 33, 101));/*Safari 4+, Chrome 2+*/
    background-image: -moz-linear-gradient(top, rgb(37, 69, 138), rgb(1, 33, 101));/*FF 3.6+*/
    background-image: -o-linear-gradient(top, rgb(37, 69, 138), rgb(1, 33, 101));/*Opera 11.10*/
    background-image: -ms-linear-gradient(rgb(37, 69, 138), rgb(1, 33, 101), top);/*IE 9*/
    border: 1px;
    border-style: solid;
    border-color: #45629b #45629b #011b4f #011b4f;
    vertical-align: middle;
    font-size: .875rem;
    font-weight: bold;
    text-transform: none;
}

.hh-btn--inverted-color {
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#26addf',endColorstr='#0188ba',GradientType=0);/*IE 7-8-9, requires hex-color for proper colorizing*/
}
.hh-btn--inverted-color:hover {
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#25458a',endColorstr='#012165',GradientType=0);/*IE 7-8-9, requires hex-color for proper colorizing*/
}

.hh-btn--normal-color:hover {
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#26addf',endColorstr='#0188ba',GradientType=0);/*IE 7-8-9, requires hex-color for proper colorizing*/
}

div.top-navigation-flyout-arrow {
    display: none;
    //position: relative;

    /* Hintergrund Pfeil */
    height: 22px;
    background-image: URL("../images/hays_menu/top-menu-arrow.jpg");
    background-position-x: 50%;
    background-position-y: -9px;
    background-repeat: no-repeat;
}

ul li.top-navigation-flyout-link:hover > div.top-navigation-flyout-arrow {
    display: block;
}